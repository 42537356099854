<template>
  <v-autocomplete
    :no-data-text="$t('general.fields.noDataAvailable')"
    :ref="refer"
    item-color="secondary"
    :items="items"
    :label="label"
    :item-text="text"
    multiple
    :item-value="value"
    v-model="question.answers_names"
    required
    class="required"
    :rules="rules"
    @mouseup="checkState"
    :return-object="returnObject"
    persistent-hint
    small
    flat
    outlined
    dense
    color="secondary"
    clearable
    :background-color="backgroundColor"
    :error-messages="errorMessages"
    :error="errorPunctuation"
    @change="checkExtra()"
  >
    <template v-slot:selection="{ item, index }">
      <v-chip small v-if="index === 0">
        <span>{{ item[text] | truncate(50) }}</span>
      </v-chip>
      <span v-if="index === 1" class="grey&#45;&#45;text caption"
        >(+{{ question.answers_names.length - 1 }}
        {{ $t('general.titles.others') }})</span
      >
    </template>
    <template v-slot:item="{ item, attrs }">
      <div style="max-width: 360px">
        <v-checkbox
          :input-value="attrs.inputValue"
          color="secondary"
          class="pt-2 pb-2 mt-0"
          :hide-details="true"
        >
          <template v-slot:label>
            <read-more
              :classes="'mb-0'"
              :text="item[text]"
              :max-chars="80"
              :font-size="14"
            />
          </template>
        </v-checkbox>
      </div>
    </template>
    <template v-slot:prepend-item>
      <v-checkbox
        v-model="checkboxState"
        color="secondary"
        class="pt-2 pb-2 mt-0 pl-4"
        :hide-details="true"
        @change="selectAll"
      >
        <template v-slot:label>
          <v-btn small color="secondary" x-small text>
            {{ $t('general.titles.selectAll') }}
          </v-btn>
        </template>
      </v-checkbox>
      <v-divider class="mb-2"></v-divider>
    </template>
  </v-autocomplete>
</template>
<script>
  import ReadMore from '../../components/ReadMoreComponent'
  import _ from 'lodash'
  export default {
    name: 'AutocompleteAnswer',
    props: {
      question: {
        type: Object,
        required: true,
      },
      section: {
        type: Object,
        required: true,
      },
      refer: {
        type: String,
        required: true,
      },
      text: {
        type: String,
        required: true,
      },
      value: {
        type: String,
        required: true,
      },
      items: {
        type: Array,
        required: true,
      },
      rules: {
        type: Array,
        required: true,
      },
      label: {
        type: String,
        required: true,
      },
      backgroundColor: {
        type: String,
      },
      errorMessages: {
        type: String,
      },
      errorPunctuation: {
        type: Boolean,
      },
      returnObject: {
        type: Boolean,
        required: false,
      },
    },
    components: {
      ReadMore,
    },
    data() {
      return {
        checkboxState: false,
      }
    },

    methods: {
      checkExtra() {
        this.$root.$emit('checkExtra', this.question, this.section)
      },
      checkState() {
        if (
          this.question.answers_names.length ===
          this.$refs[this.refer].filteredItems.length
        ) {
          this.checkboxState = true
        } else {
          this.checkboxState = false
        }
      },
      selectAll() {
        if (this.checkboxState === false) {
          this.question.answers_names = []
        } else {
          let aux = []
          if (this.question.answers_names.length > 0) {
            _.forEach(this.question.answers_names, name => {
              const object = _.find(this.$refs[this.refer].filteredItems, o => {
                return o.answer_name === name
              })
              if (!object)
                this.$refs[this.refer].filteredItems.push(object)
            })
            aux = this.$refs[this.refer].filteredItems
          } else {
            aux = this.$refs[this.refer].filteredItems
          }

          this.$set(
            this.question,
            'answers_names',
            this.getByProperty(aux, 'answer_name')
          )
        }
        this.checkExtra()
      },
      getByProperty(array, property) {
        return _.map(array, property)
      },
    },
  }
</script>
