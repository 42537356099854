import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMenu,{attrs:{"close-on-content-click":false,"nudge-width":300,"offset-x":"","max-width":400},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({staticClass:"mx-2",attrs:{"color":"secondary","dark":"","text":"","small":""}},'v-btn',attrs,false),on),[_c(VIcon,{staticClass:"pa-2",attrs:{"color":"#0f0d0d80","small":""}},[_vm._v(" mdi-information-outline ")])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VCard,[_c(VCardText,{staticStyle:{"overflow-y":"auto","max-height":"200px"}},[_vm._v(" "+_vm._s(_vm.message)+" ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" "+_vm._s(_vm.$t('general.buttons.cancel'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }