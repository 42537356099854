<template>
  <v-container fluid>
    <v-list-item three-line class="ma-4">
      <v-list-item-content>
        <p class="display-1 text--primary">
          {{ inspection.name ? inspection.name : '' }}
        </p>
      </v-list-item-content>
    </v-list-item>
    <v-card
        class="mx-auto mr-7 ml-8 mt-1"
        flat
        outlined
    >
      <v-skeleton-loader
          :loading="!ready"
          class="mx-auto"
          max-height="200"
          type="card"
      >
        <v-app-bar elevation="0" flat class="mb-4">
          <h2>{{ count > 0 ? $t('survey.fields.general_data') : $t('inspections.labels.not_permissions_template') }}</h2>
        </v-app-bar>

        <v-card-text v-if="count > 0">
          <v-form ref="form" v-model="valid1" style="height: auto; max-height: max-content">
            <v-row class="d-flex">
              <v-col cols="4">
                <v-datetime-picker
                    v-model="computedSurveyDate"
                    :label="$t('records.fields.date')"
                    :locale="$i18n.locale"
                    :show-date="true"
                    :show-time="true"
                    :text-field-props="textProps"
                    color="secondary"
                    date-format="dd / MM / yyyy"
                    time-format="HH:mm"
                />
              </v-col>
              <v-col cols="4">
                <v-autocomplete
                    v-model="location_origin"
                    :clearable="true"
                    :items="locations"
                    :label="$t('documents.fields_add.locations')"
                    :no-data-text="$t('general.fields.noDataAvailable')"
                    :rules="requireRules"
                    background-color="#EAEAEA80"
                    class="required"
                    item-color="secondary"
                    item-text="name"
                    item-value="id"
                    return-object
                    small-chips
                    persistent-hint
                    small
                    flat
                    outlined
                    dense
                    color="secondary"
                    clearable
                    :loading="loadings.origin"
                    @click:clear="fetchLocations(null)"
                    @change="fetchStorages(null, location_origin)"
                    @update:search-input="fetchLocations($event)"
                >
                  <template v-slot:label>
                    {{ $t('documents.fields_add.locations') }}
                    <span class="red--text"><strong>*</strong></span>
                  </template>
                  <template v-slot:prepend-item>
                    <v-alert
                      color="secondary"
                      type="info"
                      dense
                      border="right"
                      text
                      tile
                      class="ma-0"
                    >
                      <template #append>
                        <code><v-icon color="secondary" v-text="'mdi-magnify'"/></code>
                      </template>
                      <template #prepend>
                        <v-icon class="mr-2" color="secondary" v-text="'mdi-information-slab-circle-outline'"/>
                      </template>
                      {{ $t('general.fields.startTyping') }}
                    </v-alert>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="4">
                <AutocompleteLocations
                    :disabled_prop="!location_origin"
                    :items="storages"
                    :label="$t('documents.fields_add.storage_device')"
                    :object="inspection"
                    :refer="'storageDevicesFilteredFilter'"
                    :return-object="true"
                    :rules="selectRule"
                    :text="'control_label'"
                    :value="'id'"
                    :loading_prop="loadings.destination"
                    @click:clear="fetchStorages($event, location_origin)"
                    @update:storages="fetchStorages($event, location_origin)"
                >
                  <template v-slot:label>
                    {{ $t('documents.fields_add.storage_device') }}
                    <span class="red--text"><strong>*</strong></span>
                  </template>
                  <template v-slot:search-info>
                    <v-alert
                      color="secondary"
                      type="info"
                      dense
                      border="right"
                      text
                      tile
                      class="ma-0"
                    >
                      <template #append>
                        <code><v-icon color="secondary" v-text="'mdi-magnify'"/></code>
                      </template>
                      <template #prepend>
                        <v-icon class="mr-2" color="secondary" v-text="'mdi-information-slab-circle-outline'"/>
                      </template>
                      {{ $t('general.fields.startTyping') }}
                    </v-alert>
                  </template>
                </AutocompleteLocations>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-skeleton-loader>
    </v-card>

    <template>
      <v-expansion-panels id="top" v-model="panel" class="mt-12 pt-12">
        <v-expansion-panel
            v-for="(sectionValues, index) in widthPermission(inspection.questionSectionList)"
            v-if="ready && hideSectionsWithExtraQuestions(sectionValues)"
            :id="`top${sectionValues.id}`"
            class="my-2 py-0"
            width="100%"
        >
          <v-expansion-panel-header
              @click="editStep(sectionValues, index)"
          >
            <div class="d-flex flex-column">
              <div class="pl-4">
                <span style=" font-size: 20px; color: #03AC8C">
                  {{ $t("survey.titles.section_title") + ": " }}
                </span>
                <span style=" font-size: 20px">
                <strong><v-chip disabled small>{{ index + 1 }}</v-chip> {{ sectionValues["name"] }}</strong></span>
              </div>
              <small v-if="validateInspectionOrSection('section', sectionValues)"
                     style="font-size: 12px; margin-left: 117px">
                <span style="color: #e83b36"><strong>{{ $t('general.rules.requiredFields') }} </strong></span>
              </small>
            </div>
          </v-expansion-panel-header>

          <v-expansion-panel-content
              v-if="ready && checkAccess(sectionValues)"
              :ref="sectionValues.id"
              eager
          >
            <v-card
                v-show="sections[sectionValues.id].visibility"
                :elevation="0"
                class="mx-auto pa-3"
                flat
                outlined
            >
              <v-card-text class="mt-5 pa-0 mx-auto my-auto">
                <v-form ref="form" v-model="valid2[sectionValues.id]">
                  <v-simple-table style="background-color: #f2f2f2">
                    <template v-slot:default>
                      <thead class="pt-5 pb-5">
                      <tr>
                        <th class="text-left table-th-style" style="width: 300px">
                          {{ $t('inspections.labels.questions_table') }}
                        </th>
                        <th class="text-left table-th-style" style="width: 300px">
                          {{ $t('inspections.labels.answers_table') }}
                        </th>
                        <th class="text-center table-th-style" style="width: 150px">
                          {{ $t('inspections.labels.actions_table') }}
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <template v-for="question in sections[sectionValues.id].selected"
                                v-if="sections[sectionValues.id].visibility === true">
                        <tr
                            v-if="
                            question.show === true ||
                            !question.extraQuestionFatherRef
                          "
                            v-bind:key="question.id"
                        >
                          <td
                              class="pt-3"
                              style="font-size: 20px; color: #0f0d0d"
                          >
                        <span
                            class="
                            d-flex
                            justify-start
                            align-content-center align-center
                            flex-nowrap flex-row
                          "
                        >
                          <read-more
                              :classes="'pt-2'"
                              :font-size="20"
                              :max-chars="60"
                              :text="question.question"
                          />
                          <QuestionInfoDialog  v-if="
                              question.questionHelp &&
                              question.questionHelp.text
                            "
                             :message="question.questionHelp.text"
                          />
                        </span>
                          </td>
                          <td
                              style="
                          font-size: 24px;
                          color: #0f0d0d;
                          padding-top: 25px;
                        "
                          >
                            <!-- TESTAREA -->
                            <v-textarea
                                v-if="question.questionStructureType === 'TEXT'"
                                v-model="question.answers_names[0]"
                                :auto-grow="true"
                                :clearable="true"
                                :label="$t('survey.fields.answer')"
                                :no-resize="true"
                                :row-height="40"
                                :rows="3"
                                :rules="question.isRequired ? requireRules : []"
                                background-color="#EAEAEA80"
                                counter
                                onkeypress="return (event.charCode != 34)"
                                persistent-hint
                                small
                                flat
                                outlined
                                dense
                                color="secondary"
                                clearable
                                @input="checkExtra(question, sectionValues)"
                                v-on:keyup.86="replace"
                                v-on:keyup.ctrl.86="replace"
                            >
                              <template v-slot:label>
                                {{ $t('survey.fields.answer') }}
                                <span v-if="question.isRequired" class="red--text"
                                ><strong>*</strong></span
                                >
                              </template>
                            </v-textarea>

                            <!-- TEXT -->
                            <v-text-field
                                v-if="
                            question.questionStructureType === 'LOTE' ||
                            question.questionStructureType === 'LACRE'||
                            question.questionStructureType === 'QR'
                          "
                                v-model="question.answers_names[0]"
                                :clearable="true"
                                :label="$t('survey.fields.answer')"
                                :rules="question.isRequired ? requireRules : []"
                                background-color="#EAEAEA80"
                                onkeypress="return (event.charCode != 34)"
                                persistent-hint
                                small
                                flat
                                outlined
                                dense
                                color="secondary"
                                clearable
                                type="text"
                                @input="checkExtra(question, sectionValues)"
                                v-on:keyup.86="replace"
                                v-on:keyup.ctrl.86="replace"
                            >
                              <template v-slot:label>
                                {{ $t('survey.fields.answer') }}
                                <span v-if="question.isRequired" class="red--text"
                                ><strong>*</strong></span
                                >
                              </template>
                            </v-text-field>

                            <!-- MULTIPLES -->
                            <AutocompleteAnswer
                                v-if="
                              question.questionStructureType === 'PRODUCT' ||
                              question.questionStructureType === 'EQUIPMENT' ||
                              question.questionStructureType === 'OPERATOR' ||
                              question.questionStructureType === 'INPUT' ||
                              question.questionStructureType ===
                                'MEASUREMENTUNIT' ||
                              question.questionStructureType === 'SEASON' ||
                              question.questionStructureType === 'LIST'
                            "
                                :background-color="'#EAEAEA80'"
                                :errorMessages="errorMaxPunctuation[question.id]"
                                :errorPunctuation="errorPunctuation"
                                :items="question.possibleAnswer"
                                :label="$t('survey.fields.answer')"
                                :question="question"
                                :refer="`possibleAnswerFilters_${question.id}`"
                                :return-object="false"
                                :rules="question.isRequired ? selectRule : []"
                                :section="sectionValues"
                                :text="'answer_name'"
                                :value="'answer_name'"
                            />
                            <!--@data_search="searchPossibleAnswer($event, question)"-->

                            <!-- SIMPLES -->
                            <v-autocomplete
                                v-if="
                            question.questionStructureType ===
                              'PRODUCTSIMPLE' ||
                            question.questionStructureType ===
                              'EQUIPMENTSIMPLE' ||
                            question.questionStructureType ===
                              'OPERATORSIMPLE' ||
                            question.questionStructureType === 'INPUTSIMPLE' ||
                            question.questionStructureType ===
                              'MEASUREMENTUNITSIMPLE' ||
                            question.questionStructureType === 'SEASONSIMPLE' ||
                            question.questionStructureType === 'LISTSIMPLE'
                          "
                                v-model="question.answers_names[0]"
                                :items="question.possibleAnswer"
                                :label="$t('survey.fields.answer')"
                                :no-data-text="$t('general.fields.noDataAvailable')"
                                :rules="question.isRequired ? selectRule : []"
                                background-color="#EAEAEA80"
                                item-color="secondary"
                                item-text="answer_name"
                                item-value="answer_name"
                                small-chips
                                persistent-hint
                                small
                                flat
                                outlined
                                dense
                                color="secondary"
                                clearable
                                @change="checkExtra(question, sectionValues)"
                            >
                              <!--@update:search-input="searchPossibleAnswer($event, question.id, question.questionStructureType)"-->
                              <template v-slot:label>
                                {{ $t('survey.fields.answer') }}
                                <span v-if="question.isRequired" class="red--text"
                                ><strong>*</strong></span
                                >
                              </template>
                              <template v-slot:selection="{ item }">
                                <v-chip small>
                                  {{ item.answer_name | truncate(50) }}
                                </v-chip
                                >
                              </template>
                              <template v-slot:item="{ item }">
                                <div style="max-width: 360px">
                                  <read-more
                                      :classes="'mb-0'"
                                      :font-size="14"
                                      :max-chars="80"
                                      :text="item.answer_name"
                                  />
                                </div>
                              </template>
                            </v-autocomplete>

                            <!--NUMBER-->
                            <v-currency-field
                                :default-value="null"
                                :value-as-integer="false"
                                v-if="
                            question.questionStructureType === 'NUMBER'
                          "
                                v-model="question.answers_names[0]"
                                :auto-decimal-mode="false"
                                :decimal-length="decimalFormat(question)"
                                :label="$t('survey.fields.answer')"
                                :rules="
                            question.isRequired
                              ? requireRules
                              : []
                          "
                                background-color="#EAEAEA80"
                                locale="en"
                                onkeypress="return (event.charCode != 34)"
                                persistent-hint
                                flat
                                outlined
                                dense
                                color="secondary"
                                clearable
                                type="text"
                                @input="checkExtra(question, sectionValues)"
                            >
                              <template v-slot:label>
                                {{ $t('survey.fields.answer') }}
                                <span v-if="question.isRequired" class="red--text"
                                ><strong>*</strong></span
                                >
                              </template>
                            </v-currency-field>

                            <!--DATETIME-->
                            <v-datetime-picker
                                v-if="question.questionStructureType === 'DATATIME'"
                                v-model="question.answers_names[0]"
                                :label="$t('survey.fields.answer')"
                                :locale="$i18n.locale"
                                :required="!!question.isRequired"
                                :show-date="true"
                                :show-time="true"
                                :text-field-props="textProps"
                                color="secondary"
                                date-format="dd / MM / yyyy"
                                @valid:datetime="valid2[sectionValues.id] = $event"
                                @input="checkExtra(question, sectionValues)"
                            />

                            <!--DATE-->
                            <v-datetime-picker
                                v-if="question.questionStructureType === 'DATA'"
                                v-model="question.answers_names[0]"
                                :label="$t('survey.fields.answer')"
                                :locale="$i18n.locale"
                                :required="!!question.isRequired"
                                :show-date="true"
                                :text-field-props="textProps"
                                color="secondary"
                                date-format="dd / MM / yyyy"
                                @valid:datetime="valid2[sectionValues.id] = $event"
                                @input="checkExtra(question, sectionValues)"
                               :show-time="false"
                            />

                            <!--TIME-->
                            <v-datetime-picker
                                v-if="question.questionStructureType === 'TIME'"
                                v-model="question.answers_names[0]"
                                :label="$t('survey.fields.answer')"
                                :locale="$i18n.locale"
                                :required="!!question.isRequired"
                                :show-time="true"
                                :text-field-props="textProps"
                                color="secondary"
                                time-format="HH:mm"
                                @valid:datetime="valid2[sectionValues.id] = $event"
                                @input="checkExtra(question, sectionValues)"
                                :dialog-width="290"
                                :show-date="false"
                            />
                          </td>
                          <td class="text-center" style="border: hidden">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-badge
                                    :content="
                                question.evidences.length > 0 &&
                                question.documents.length > 0
                                  ? question.documents.length
                                  : question.evidences &&
                                    question.evidences.length > 0
                                  ? question.evidences.length
                                  : question.documents &&
                                    question.documents.length > 0
                                  ? question.documents.length
                                  : 0
                              "
                                    :value="
                                (question.evidences &&
                                  question.evidences.length > 0) ||
                                (question.documents &&
                                  question.documents.length > 0)
                              "
                                    class="ma-2"
                                    color="secondary accent-4"
                                    overlap
                                >
                                  <v-chip
                                      v-bind="attrs"
                                      v-on="on"
                                      @click="
                                  openDialog(false, sectionValues.id, question)
                                "
                                  >
                                    <v-icon> mdi-file-upload-outline</v-icon>
                                    <!--EVIDENCIAS-->
                                  </v-chip>
                                </v-badge>
                              </template>
                              <span>{{
                                  $t('inspections.actions.upload_evidences')
                                }}</span>
                            </v-tooltip>

                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-chip
                                    v-bind="attrs"
                                    v-on="on"
                                    class="ma-1"
                                    @click="
                                openDialog(true, sectionValues.id, question)
                              "
                                >
                                  <v-icon v-if="!question.coments">
                                    mdi-text-box-plus-outline
                                  </v-icon>
                                  <v-icon v-else color="secondary">
                                    mdi-text-box-check
                                  </v-icon>
                                  <!--COMENTARIOS-->
                                </v-chip>
                              </template>
                              <span>{{
                                  $t('inspections.actions.add_comment')
                                }}</span>
                            </v-tooltip>
                          </td>
                        </tr>
                      </template>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-spacer/>
                <v-btn v-if="!(sectionValues.questions.length <= questionsLimitBySection)" :disabled="sectionValues.questions[0].id === sections[sectionValues.id].selected[0].id" color="neuter" text
                       x-large
                       @click="beforeQuestions(sectionValues, index)"
                >
                  {{ $t('general.buttons.back') }}
                </v-btn>
                <v-btn v-if="!(sectionValues.questions.length <= questionsLimitBySection) && sections[sectionValues.id].selected.length <= questionsLimitBySection"
                       :disabled="sections[sectionValues.id].paginated.length === 1 || sections[sectionValues.id].selected.length < 10"
                       color="secondary"
                       text
                       x-large
                       @click="nextQuestions(sectionValues, index)"
                >
                  {{ $t('general.buttons.next_page') }}
                </v-btn>
                <v-spacer/>

              </v-card-actions>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>

    <v-fade-transition
        v-if="
        ready &&
        inspectionSelected.signaturesPlaced &&
        inspectionSelected.signaturesPlaced.length > 0
      "
        mode="out-in"
    >
      <v-row class="d-block text-center">
        <v-col cols="12">
          <v-card class="mx-auto mr-7 ml-8 mt-10 pa-3" elevation="0">
            <v-img
                :src="`${
                  signature.file
                  ? signature.base64
                  : inspectionSelected.signaturesPlaced[0].signature.s3_url
              }`"
                contain
                height="240"
            />
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card class="mx-auto mr-7 ml-8 pa-3" elevation="0">
            {{
              signature.description
                  ? signature.description
                  : inspectionSelected.signaturesPlaced[0].description
            }}
          </v-card>
        </v-col>
      </v-row>
    </v-fade-transition>

    <v-row>
      <v-col class="text-right pr-6" cols="12">
        <v-spacer></v-spacer>
        <v-btn color="gray" text @click="back()">
          {{ $t('general.buttons.cancel') }}
        </v-btn>
        <vue-signature
            v-if="inspection.signatureRequired"
            :disabled="!valid1 || validQuestions || validateInspectionOrSection('inspection', inspection)"
        />
        <v-btn
            :disabled="
            !valid1 ||
            (!((signature.file && signature.description.length > 0)                                        ||
               (inspection.signaturesPlaced.length > 0 && inspection.signaturesPlaced[0].signature.s3_url))&&
                inspection.signatureRequired)                                                              ||
                validQuestions                                                                             ||
                validateInspectionOrSection('inspection', inspection)
          "
            color="secondary"
            text
            @click="saveSurveyAction"
        >
          {{ $t('general.buttons.save') }}
        </v-btn>
      </v-col>
    </v-row>

    <!--Loading Dialog-->
    <v-row align-content="center" justify="center">
      <v-dialog v-model="loadingSave" max-width="670" persistent>
        <v-card class="pa-3" style="border-radius: 8px">
          <v-card-title style="font-weight: 300; font-size: 14px">
            {{ $t('inspections.labels.save_inspection') }}
          </v-card-title>
          <v-card-text>
            <v-progress-linear
                color="secondary accent-4"
                height="6"
                indeterminate
                rounded
            />
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>

    <!--Comment Dialog-->
    <v-row justify="center">
      <v-dialog v-model="dialogAddComent" max-width="670" persistent>
        <v-card class="pa-3" style="border-radius: 8px">
          <v-card-title
              class="headline ma-2"
              style="
              background-color: rgba(135, 135, 135, 0.06);
              border-radius: 8px;
              display: block;
            "
          >
            {{ $t('inspections.labels.label_head_modal') }}
          </v-card-title>

          <v-card-text class="pa-2">
            <v-textarea
                v-model="coment_text"
                :label="$t('inspections.labels.label_textarea')"
                auto-grow
                background-color="rgba(135,135,135,0.10)"
                color="secondary"
                counter
                flat
                no-resize
                onkeypress="return (event.charCode != 34)"
                solo
                v-on:keyup.86="replaceComentText"
                v-on:keyup.ctrl.86="replaceComentText"
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="gray" text @click="dialogTemplateClose(true)">
              {{ $t('general.buttons.cancel') }}
            </v-btn>
            <v-btn color="secondary" text @click="dialogTemplateSave(true)">
              {{ $t('general.buttons.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!--Upload Dialog-->
    <v-row justify="center">
      <v-dialog v-model="dialogUpload" max-width="670" persistent>
        <v-card class="pa-3" style="border-radius: 8px">
          <v-card-title
              class="headline ma-2"
              style="
              background-color: rgba(135, 135, 135, 0.06);
              border-radius: 8px;
              display: block;
            "
          >
            {{ $t('inspections.labels.label_head_upload') }}
          </v-card-title>

          <v-card-text class="pa-2">
            <v-form ref="form3">
              <v-file-input
                  v-model="filesSelected"
                  :rules="fileTypeRulesAndSize(filesSelected)"
                  color="secondary"
                  label="File input"
                  multiple
                  small-chips
                  @change="addDocument"
              >
              </v-file-input>
            </v-form>
          </v-card-text>
          <v-row
              v-if="
              questionSelected.evidences &&
              questionSelected.evidences.length > 0
            "
              align-content="center"
              justify="center"
          >
            <v-col cols="8">
              <v-card v-show="loadingDoc" flat>
                <v-card-title style="font-weight: 300; font-size: 14px">
                  {{ $t('general.ui.loading_evidences') }}
                </v-card-title>
                <v-card-text>
                  <v-progress-linear
                      color="secondary accent-4"
                      height="6"
                      indeterminate
                      rounded
                  />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-card-text
              :loading="documentLoad"
              class="mb-12"
              elevation="0"
              height="350px"
          >
            <v-list class="overflow-y-auto" max-height="300">
              <v-list-item
                  v-for="(doc, index) in documentsUpload"
                  v-bind:key="index"
              >
                <v-list-item-avatar>
                  <v-icon>mdi-file-image-outline</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title v-text="doc.name"/>
                </v-list-item-content>

                <v-list-item-icon color="secondary">
                  <v-icon @click="deleteFile(doc, index)">
                    mdi-minus-circle-outline
                  </v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="gray" text @click="dialogTemplateClose(false)">
              {{ $t('general.buttons.cancel') }}
            </v-btn>
            <v-btn color="secondary" text @click="dialogTemplateSave(false)">
              {{ $t('general.buttons.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import moment from 'moment'
import i18n from '@/plugins/i18n'
import _ from 'lodash'
import SurveyService from '../../services/SurveyService'
import PermisionService from '../../services/PermisionService'
import LanguajeService from '../../services/LanguajeService'
import SortService from '../../services/OrderListService'
import VueSignature from '../../components/vue-signature/VueSignature'
import ReadMore from '@/components/ReadMoreComponent'
import AutocompleteLocations from '@/components/AutocompleteLocations'
import AutocompleteAnswer from './AutocompleAnswer'
import QuestionInfoDialog from '@/module/inspections/components/QuestionInfoDialog'

const STRUCTURE_TYPE = {
  MULTIPLE: [
    'PRODUCT',
    'EQUIPMENT',
    'OPERATOR',
    'INPUT',
    'MEASUREMENTUNIT',
    'SEASON',
    'LIST',
  ],
  SIMPLE: [
    'NUMBER',
    'TEXT',
    'QR',
    'LOTE',
    'LACRE',
    'TIME',
    'DATA',
    'DATATIME',
    'PRODUCTSIMPLE',
    'EQUIPMENTSIMPLE',
    'OPERATORSIMPLE',
    'INPUTSIMPLE',
    'MEASUREMENTUNITSIMPLE',
    'SEASONSIMPLE',
    'LISTSIMPLE',
  ],
}

const PROFILE = JSON.parse(localStorage.getItem('profile'));

export default {
  components: { QuestionInfoDialog, ReadMore, VueSignature, AutocompleteLocations, AutocompleteAnswer},
  name: 'Survey',
  data: () => ({
    panel: 0,
    questionsLimitBySection: 10,
    steeperSections: 1,
    loadingSaveSection: {},
    loadingBackSection: {},
    filesSelected: [],
    textProps: {
      persistentHint: true,
      small: true,
      flat: true,
      outlined: true,
      dense: true,
      clearable: true,
      color: "secondary",
      itemColor: "secondary",
      backgroundColor: "#EAEAEA80"
    },
    // dialog: false,
    documentLoad: false,
    updating: false,
    editedIndexSurvey: -1,
    templateId: -1,
    ready: false,
    dialogAddComent: false,
    dialogUpload: false,
    show: false,
    profile: {},
    answerValidForm: false,
    showDialogAnswer: false,
    menuDateSurvey: false,
    menuTimeSurvey: false,
    coment_text: null,
    sectionSelected: null,
    documentsUpload: [],
    AlldocumentsUpload: [],
    AllTempDocumentsUpload: [],
    documentsUploadName: [],
    time: null,
    valid1: false,
    valid2: {},
    isValidDate: {},
    answer: {
      question: '',
      questionHelp: {},
      answer: '',
    },
    inspection: {
      id: null,
      name: '',
      data: new Date(),
      company: null,
      isTemplate: true,
      questionSectionList: [],
      locations: [],
      responsables: [],
    },
    answerSelect: null,
    sectionSelect: null,
    showDialog: false,
    max: 40,
    title: 'Información',
    dialogText: 'Añada un texto a este dialogo',
    width: 200,
    location_origin: null,
    //Actions buttons
    fab: false,
    itemsActions: [
      {
        title: i18n.t('general.buttons.edit'),
        icon: 'mdi-square-edit-outline',
        action: 'EDIT',
      },
      {
        title: i18n.t('general.buttons.downloadEvidence'),
        icon: 'mdi-download-outline',
        action: 'DOWNLOAD',
      },
    ],
    loadingSave: false,
    loadingDoc: false,
    errorMaxPunctuation: {},
    errorPunctuation: false,
    errorSDO: false,
    sections: {},
    lastIndex: 10,
    count: 0,
    defaultFilters: {
      companyId: PROFILE.company_id,
      search: "",
      pageableDTO: {
        page: 0,
        size: 15,
        sortBy: 'id',
        direction: 'ASC',
      }
    },
    loadingNomenclatorSearch: {},
    ownNomenclators: {},
    loadings: {
      origin: false,
      destination: false
    },
  }),

  computed: {
    fileSize() {
      return this.signature.file && this.signature.file.size > 6041
          ? true
          : false
    },
    decimalFormat() {
      return (question) => question.afterPoint !== null
          ? question.afterPoint
          : this.config.company.traceability.structure.number.afterPoint !== null
              ? this.config.company.traceability.structure.number.afterPoint
              : 3
    },
    config: {
      get() {
        return this.$store.getters['general/config']
      },
      set(val) {
        this.$store.commit('general/SET_CONFIG', val)
      }
    },
    questionSelected: {
      get() {
        return this.$store.getters['inspections/question']
      },
      set(val) {
        this.$store.commit('inspections/SET_QUESTION', val)
      },
    },
    inspectionSelected: {
      get() {
        return this.$store.getters['inspections/inspection']
      },
      set(val) {
        this.$store.commit('inspections/SET_INSPECTION', val)
      },
    },
    signature: {
      get() {
        return this.$store.getters['signature/GET_SIGNATURE']
      },
      set(val) {
        this.$store.commit('signature/SIGNATURE', val)
      },
    },
    ...mapGetters({
      structureType: 'details/detailsLocalEnum',
      authoritiesList: 'authority/authorities',
      loading: 'inspections/loading',
      allStoragesByCompany: 'authority/authoritiesStoragesDevices',
      locationsList: 'authority/authoritiesLocations',
      storages: 'storage/storagesList',
      locations: 'locations/locationsList',
      requireAndMaxRules: 'general/requireAndMaxRules',
      requireRules: 'general/requireRules',
      selectRule: 'general/selectRule',
      products: 'product/products',
      operators: 'operator/operators',
      equipments: 'equipment/equipments',
      seasons: 'season/seasons',
      allUnits: 'units/allObjectUnits',
      inputs: 'input/inputs',
      fileTypeRulesAndSize: 'general/fileTypeRulesAndSize',
      onlyNumberFormat: 'general/onlyNumberFormat',
      languageStructure: 'general/languageStructure',
      surveyEdit: 'inspections/inspection'
    }),
    locationsFiltered() {
      return this.getLocationsByAction()
    },
    storageDevicesFiltered() {
      return this.getStorageFiltered()
    },
    validQuestions() {
      return (
          _.filter(this.valid2, valid => {
            return !valid
          }).length > 0
      )
    },
    computedSurveyDate: {
      get() {
        return this.inspection.data
            ? new Date(this.inspection.data)
            : null
      },
      set(data) {
        this.inspection.data = data ? new Date(data) : null
      },
    },
  },

  async created() {
    this.config = JSON.parse(localStorage.getItem('config'))
    await this.initialize()
  },

  mounted() {
    this.$root.$on('checkExtra', (question, section) => {
      this.checkExtra(question, section)
    })
  },

  methods: {
    ...mapActions({
      fetchListProduct: 'product/fetchListProduct',
      fetchListOperator: 'operator/fetchListOperator',
      fetchListEquipment: 'equipment/fetchListEquipment',
      fetchListInputs: 'input/fetchListInputs',
      fetchAllUnits: 'units/fetchAllUnits',
      fetchListSeasons: 'season/fetchListSeasons',
      saveSection: 'survey/saveSection',
      fetchListAuthority: 'authority/fetchListAuthority',
      saveQuestion: 'survey/saveQuestion',
      change_state: 'survey/change_state',
      saveSurvey: 'inspections/saveSurvey',
      fetchInspectionById: 'inspections/fetchInspectionById',
      fetchPrimaryLocations: "locations/fetchFilteredLocations",
      fetchStorageLocations: "storage/fetchFilteredStorages"
    }),
    async fetchLocations(e) {
      if (e) {
        this.$set(this.inspection, 'locations', [])

        this.$set(this.loadings, 'origin', true);

        await this.fetchPrimaryLocations([{
          stringFilter: e || "",
          isActive: true
        }, this.$toast]).finally(() => {
          this.$set(this.loadings, 'origin', false);
        })
      }
    },
    async fetchStorages(e, pl) {
      console.log(e)
      console.log(pl)
      if (this.editedIndexSurvey === -1) {
        if (!e) {
          this.$set(this.inspection, 'locations', [])
          e = ""
        }
      } else {
        if (!pl) {
          this.$set(this.inspection, 'locations', [])
          this.$store.commit('storage/RESTORE_STORAGE_LIST')
        }
      }

      if (e !== null && pl) {
        this.$set(this.inspection, 'locations', [])
        this.$set(this.loadings, 'destination', true);

        if (typeof pl === 'object')
          pl = pl.id

        await this.fetchStorageLocations([{
          primaryLocationVersionIds: [pl],
          stringFilter: (e && e.target) ? e.target.value : e ? e : "",
          isActive: true
        }, this.$toast]).finally(() => {
          this.$set(this.loadings, 'destination', false);
        })
      }
    },

    // TODO: Falta endpoint para posibles respuestas
    async searchPossibleAnswer(event, question) {
      this.$set(this.loadingNomenclatorSearch, question.id, true)

      const filters = {
        companyId: PROFILE.company_id,
        search: event || "",
        pageableDTO: {
          page: 0,
          size: 15,
          sortBy: 'id',
          direction: 'ASC',
        }
      }

      switch(question.questionStructureType) {
        case "PRODUCT":
        case "PRODUCTSIMPLE":
          await this.fetchListProduct([filters, this.$toast])
            .then(() => {
              this.$set(this.ownNomenclators, question.id, this.products)
            })
            .finally(() => {
              this.$set(this.loadingNomenclatorSearch, question.id, false)
            })
          break;
        case "SEASON":
        case "SEANSONSIMPLE":
          await this.fetchListSeasons([filters, this.$toast])
            .then(() => {
              this.$set(this.ownNomenclators, question.id, this.seasons)
            })
            .finally(() => {
              this.$set(this.loadingNomenclatorSearch, question.id, false)
            })
          break;
        case "EQUIPMENT":
        case "EQUIPMENTSIMPLE":
          await this.fetchListEquipment([filters, this.$toast])
            .then(() => {
              this.$set(this.ownNomenclators, question.id, this.equipments)
            })
            .finally(() => {
              this.$set(this.loadingNomenclatorSearch, question.id, false)
            })
          break;
        case "INPUT":
        case "INPUTSIMPLE":
          await this.fetchListInputs([filters, this.$toast])
            .then(() => {
              this.$set(this.ownNomenclators, question.id, this.inputs)
            })
            .finally(() => {
              this.$set(this.loadingNomenclatorSearch, question.id, false)
            })
          break;
        case "OPERATOR":
        case "OPERATORSIMPLE":
          await this.fetchListOperator([filters, this.$toast])
            .then(() => {
              this.$set(this.ownNomenclators, question.id, this.operators)
            })
            .finally(() => {
              this.$set(this.loadingNomenclatorSearch, question.id, false)
            })
          break;
      }
    },
    clearSDO(event) {
      if (event instanceof Array) {
        this.errorSDO = event.length === 0
      }
    },
    hideSectionsWithExtraQuestions(section) {
      let questions = _.filter(section.questions, o => {
        return o.extraQuestionFatherRef !== null && !o.show
      })

      return questions.length !== section.questions.length
    },
    selectOrNotAll(question, action) {
      let answers_names = []

      if (action === 'all') {
        _.forEach(question.possibleAnswer, answer => {
          answers_names.push(answer.answer_name)
        })
      }

      question.answers_names = answers_names

      this.checkExtra(question, question.answers_names)
      this.maxPunctuation(question)

      return answers_names
    },
    maxPunctuation(question) {
      if (
          question.moreAnswerPointsThanQuestion === false &&
          question.answers_names.length > 1
      ) {
        let punctuation = 0
        _.forEach(question.answers_names, name => {
          let item = _.find(question.possibleAnswer, {answer_name: name})
          if (item) {
            punctuation += item.punctuation
          }
        })
        if (punctuation.toFixed(2) > question.questionPunctuation) {
          this.$set(
              this.errorMaxPunctuation,
              question.id,
              i18n.t('inspections.rules.max_points_by_question')
          )
          this.errorPunctuation = true
        } else {
          this.$set(this.errorMaxPunctuation, question.id, '')
          this.errorPunctuation = false
        }
      } else {
        this.$set(this.errorMaxPunctuation, question.id, '')
        this.errorPunctuation = false
      }
    },
    async initialize() {
      this.ready = false
      this.editedIndexSurvey = JSON.parse(
          localStorage.getItem('editedIndexSurvey')
      )
      this.templateId = JSON.parse(localStorage.getItem('templateId'))
      try {
        await this.loadDependencies()
      } finally {
        console.log('finally')
        this.inspection = {}
        this.inspection = Object.assign({}, this.surveyEdit)
        this.AlldocumentsUpload =
            this.inspection.documents_file === undefined
                ? []
                : this.inspection.documents_file
        this.inspection.questionSectionList = _.map(
            this.inspection.questionSectionList,
            section => {
              return {
                ...section,
                questions: _.map(section.questions, question => {
                  if (
                      ['NUMBER'].includes(question.questionStructureType)
                  ) {
                    if (
                        !(
                            question.answers_names &&
                            question.answers_names.length > 0
                        )
                    ) {
                      question.answers_names[0] = ''
                    }
                  }
                  return {
                    ...question,
                    possibleAnswer:
                        question.possibleAnswer &&
                        question.possibleAnswer.length > 0
                            ? SortService.orderListByUppercase(
                            question.possibleAnswer,
                            'answer_name'
                            )
                            : [],
                  }
                }),
              }
            }
        )

        let key = null
        _.forEach(this.inspection.questionSectionList, (section) => {
          if (this.checkAccess(section)) {
            if (!key) key = section.id
            this.getQuestions(section, 0)
          }
        })

        if (this.sections[key]) {
          this.$set(this.sections[key], 'visibility', true)
        }

        this.count = _.filter(this.sections, {visibility: true}).length

        console.log(this.inspection)
        if (this.editedIndexSurvey > 0) {
          await this.fetchPrimaryLocations([{
            versionIds: [this.inspection.locations[0].primaryLocation.id],
            onlyCurrentVersion: false
          }, this.$toast]).finally(() => {
            this.location_origin = this.inspection.locations[0].primaryLocation.id
          })

          await this.fetchStorageLocations([{
            primaryLocationVersionIds: [this.inspection.locations[0].primaryLocation.id],
            versionIds: _.map(this.inspection.locations, (s) => { return s.id }),
            onlyCurrentVersion: false
          }, this.$toast]).finally(() => {
            this.$set(this.inspection, 'locations', this.inspection.locations)
          })
        } else {
          this.inspection.data = new Date().toJSON()
          this.inspection.isTemplate = false
        }
        this.ready = true
      }
    },

    formatQuestionByType(question) {
      if (
          ['NUMBER'].includes(question.questionStructureType)
      ) {
        if (
            !(
                question.answers_names &&
                question.answers_names.length > 0
            )
        ) {
          question.answers_names[0] = ''
        }
      }
    },

    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, {type: mime})
    },

    getQuestions(section, current) {
      let withoutExtra = _.filter(section.questions, { extraQuestionFatherRef: null })
      let withExtraAndTrue = _.filter(section.questions, (q) => {
        if (q.extraQuestionFatherRef !== null && q.show === true) {
          return q
        }
      })

      let allQuestions = []

      if (withoutExtra.length > 0 || withExtraAndTrue.length > 0)
        allQuestions = _.sortBy([ ...withoutExtra, ...withExtraAndTrue ], ["orderCriteria"])

      let newArray = []

      for (let i = 0; i < allQuestions.length; i = i + 10) {
        newArray.push(allQuestions.slice(i, i + 10));
      }

      this.$set(this.sections, section.id, {
        selected: newArray[current],
        paginated: newArray,
        all: section.questions,
        visibility: true,
        current: current,
        total: section.questions.length,
        perPage: 10
      })
    },

    nextQuestions(section, index) {
      if (this.valid2[section.id]) {
        this.sections[section.id].current += 1

        this.$set(this.sections[section.id], 'selected', this.sections[section.id].paginated[this.sections[section.id].current])

        if (index !== 0)
          document.location.href = `#top${this.inspection.questionSectionList[index - 1].id}`;
        else
          document.location.href = `#top`;

      } else {
        this.$refs.form.validate()
      }
    },

    beforeQuestions(section, index) {
      this.sections[section.id].current -= 1

      this.$set(this.sections[section.id], 'selected', this.sections[section.id].paginated[this.sections[section.id].current])

      if (index !== 0)
        document.location.href = `#top${this.inspection.questionSectionList[index - 1].id}`;
      else
        document.location.href = `#top`;
    },

    editStep(next, index) {
      this.lastIndex = 10;
      const back = _.find(this.sections, {visibility: true})

      const current = _.find(this.inspection.questionSectionList, {id: back.selected[0].questionSectionId})

      if (!this.validateInspectionOrSection('section', current))
        this.$set(this.sections[back.selected[0].questionSectionId], 'visibility', false)

      this.$set(this.sections[next.id], 'visibility', true)

      if (index !== 0)
        document.location.href = `#top${back.selected[0].questionSectionId}`;
      else
        document.location.href = `#top`;
    },

    async nextSection(current, index) {
      this.$set(this.loadingSaveSection, index, true)
      // salvar primero


      // luego cargar siguiente seccion
      let next = this.inspection.questionSectionList[index + 1]

      // llamada al endpoint
      setTimeout(() => {
        this.$set(this.loadingSaveSection, index, false)
      }, 4000)

      this.lastIndex = this.questionsLimitBySection
      this.getQuestions(next)

      this.$set(this.sections[current.id], 'visibility', false)
      this.$set(this.sections[next.id], 'visibility', true)

      if (Object.keys(next).length > 2)
        this.steeperSections = index + 1

      document.location.href = `#top${current.id}`;
    },

    async backSection(current, index) {
      this.$set(this.loadingBackSection, index, true)
      // cargar primero


      // luego pasar a la anterior seccion
      const back = this.inspection.questionSectionList[index - 1]

      // llamada al endpoint
      setTimeout(() => {
        this.$set(this.loadingBackSection, index, false)
      }, 4000)

      this.lastIndex = this.questionsLimitBySection
      this.getQuestions(back)

      this.$set(this.sections[current.id], 'visibility', false)
      this.$set(this.sections[back.id], 'visibility', true)

      if (Object.keys(back).length > 2)
        this.steeperSections = index - 1

      document.location.href = `#top${current.id}`;
    },

    widthPermission(sections) {
      let newSections = []
      _.forEach(sections, (section) => {
        if (this.checkAccess(section))
          newSections.push(section)
      })
      return newSections
    },

    async loadDependencies() {
      const promises = []
      // add here a list of promises;
      this.profile = JSON.parse(localStorage.getItem('profile'))

      if (this.editedIndexSurvey === -1) {
        promises.push(this.fetchPrimaryLocations([this.defaultFilters, this.$toast]))
      }

      promises.push(this.fetchListProduct([this.defaultFilters, this.$toast]))
      promises.push(this.fetchListOperator([this.defaultFilters, this.$toast]))
      promises.push(this.fetchListEquipment([this.defaultFilters, this.$toast]))
      promises.push(this.fetchListInputs([this.defaultFilters, this.$toast]))
      promises.push(this.fetchListSeasons([this.defaultFilters, this.$toast]))
      promises.push(
          this.fetchInspectionById([
            this.editedIndexSurvey > 0
                ? this.editedIndexSurvey
                : this.templateId,
            this.$toast,
          ])
      )
      promises.push(this.fetchAllUnits(this.$toast))

      return await Promise.all(promises)
    },

    changeLocation() {
      if (!this.inspection.locations) {
        this.inspection.locations = []
      } else {
        this.clearLocations()
      }
    },

    clearLocations() {
      this.inspection.locations = []
      this.$refs.form[0].resetValidation()
    },

    checkExtra(target, section) {
      this.inspection.questionSectionList = _.map(
          this.inspection.questionSectionList,
          section => {
            return {
              ...section,
              questions: _.map(section.questions, question => {
                if (
                    !['DATATIME', 'TIME', 'DATA'].includes(
                        target.questionStructureType
                    )
                ) {
                  if (
                      question.extraQuestionFatherRef &&
                      question.extraQuestionFatherRef.id === target.id
                  ) {
                    if (
                        target.answers_names.includes(question.condicionalAnswer)
                    ) {
                      return {
                        ...question,
                        show: true,
                      }
                    } else {
                      return {
                        ...question,
                        answers: [],
                        answers_names: [],
                        show: false,
                      }
                    }
                  } else {
                    return question
                  }
                } else {
                  if (
                      question.extraQuestionFatherRef &&
                      question.extraQuestionFatherRef.id === target.id
                  ) {
                    if (target.answers_names && target.answers_names[0]) {
                      return {
                        ...question,
                        show: true,
                      }
                    } else {
                      return {
                        ...question,
                        answers: [],
                        answers_names: [],
                        show: false,
                      }
                    }
                  } else {
                    return question
                  }
                }
              }),
            }
          }
      )

      if (STRUCTURE_TYPE.MULTIPLE.includes(target.questionStructureType) && (target.id !== section.id)) {
        this.maxPunctuation(target)
        this.maxPunctuation(section)
      }

      const newSection = _.find(this.inspection.questionSectionList, {id: section.id})

      if (newSection && newSection.questions) {
        _.forEach(newSection.questions, (question) => {
          this.formatQuestionByType(question)
        })

        this.getQuestions(newSection, this.sections[newSection.id].current)

        this.sections[newSection.id].visibility = true

        if(this.inspection.questionSectionList && this.inspection.questionSectionList.length > 0) {
          _.forEach(this.inspection.questionSectionList, (s) => {
            if (s.questions && s.questions.length > 0) {
              _.forEach(s.questions, (q) => {
                if (q.extraQuestionFatherRef && (q.extraQuestionFatherRef.id === target.id)) {
                  if (target.answers_names && target.answers_names[0]) {
                    if (target.answers_names.includes(q.condicionalAnswer)) {
                      q.show = true

                      if(this.sections[s.id].selected && this.sections[s.id].selected.length > 0) {
                        _.forEach(this.sections[s.id].selected, (q1, index) => {
                          if (q1.id === q.id) {
                            this.$set(this.sections[s.id].selected[index], 'show', true)
                          }
                        })
                      }

                      if(this.sections[s.id].all && this.sections[s.id].all.length > 0) {
                        _.forEach(this.sections[s.id].all, (q2, index) => {
                          if (q2.id === q.id) {
                            this.$set(this.sections[s.id].all[index], 'show', true)
                          }
                        })
                      }

                    } else {
                      if((s && s.length > 0) && q) {
                        this.clearExtraQuestionFields(s, q)
                      }
                    }
                  } else {
                    if((s && s.length > 0) && q) {
                      this.clearExtraQuestionFields(s, q)
                    }
                  }

                  this.$set(this.sections[s.id], 'visibility', true)
                }
              })
            }
          })
        }
      }
    },

    clearExtraQuestionFields(s, q) {
      q.answers = []
      q.answers_names = []
      q.show = false

      if((s && s.length > 0) && q) {
        _.forEach(this.sections[s.id].selected, (q1, index) => {
          if (q1.id === q.id) {
            this.$set(this.sections[s.id].selected[index], 'answers', [])
            this.$set(this.sections[s.id].selected[index], 'answers_names', [])
            this.$set(this.sections[s.id].selected[index], 'show', false)
          }
        })
        _.forEach(this.sections[s.id].all, (q2, index) => {
          if (q2.id === q.id) {
            this.$set(this.sections[s.id].all[index], 'answers', [])
            this.$set(this.sections[s.id].all[index], 'answers_names', [])
            this.$set(this.sections[s.id].all[index], 'show', false)
          }
        })
      }
    },

    openDialog(option, sectionId, question) {
      this.questionSelected = question
      this.sectionSelected = sectionId
      if (option) {
        this.dialogAddComent = true
        this.coment_text = this.inspection.questionSectionList
            .find(f => f.id === sectionId)
            .questions.find(q => q.id === this.questionSelected.id).coments
      } else {
        this.updating = false
        if (
            this.editedIndexSurvey > 0 &&
            !question.updating &&
            question.evidences &&
            question.evidences.length > 0
        ) {
          this.loadingDoc = true
          this.getEvidences(this.questionSelected.id)
        } else {
          this.loadingDoc = false
          this.documentsUploadName = this.inspection.questionSectionList
              .find(f => f.id === sectionId)
              .questions.find(q => q.id === this.questionSelected.id).documents
          this.AlldocumentsUpload.forEach(d => {
            if (this.documentsUploadName.find(un => un === d.name)) {
              this.documentsUpload.push(d)
            }
          })
        }
        this.dialogUpload = true
      }
    },

    getEvidences(questionId) {
      const scope = this
      try {
        SurveyService.getEvidencas(questionId)
            .then(response => {
              const blob = new Blob([response.data], {
                type: 'application/zip',
              })
              const jsZip = require('jszip')
              jsZip.loadAsync(blob).then(function (zip) {
                Object.keys(zip.files).forEach(function (filename) {
                  zip.files[filename].async('blob').then(function (fileData) {
                    // These are your file contents
                    let type = ''
                    const nameSpliter = filename.split('.')
                    if (nameSpliter[1] === 'pdf' || nameSpliter[1] === 'PDF') {
                      type = 'application/pdf'
                    } else {
                      type = 'image/' + nameSpliter[1]
                    }
                    scope.addDocument(
                        [new File([fileData], filename, {type: type})],
                        true
                    )
                  })
                })
                scope.documentLoad = false
              })
            })
            .finally(() => {
              this.loadingDoc = false
            })
      } catch (error) {
        this.$toast.warning(i18n.t('survey.notification.evidencesCero'), {
          queueable: true,
          color: '#777',
        })
      }
    },

    addDocument(docs, load) {
      const scope = this
      this.updating = !load
      const mimeType = [
        'image/jpeg',
        'image/jpg',
        'image/bmp',
        'image/png',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/msword',
        'text/csv',
        'application/pdf',
        'application/zip',
        'application/x-zip-compressed',
        'application/vnd.rar',
      ]
      _.forEach(docs, doc => {
        if (load || (doc.size <= 5000000 && mimeType.includes(doc.type))) {
          let dt = new Date().getTime()
          let uuid =
              'agdoc_' +
              scope.questionSelected.id +
              'xxxxxx'.replace(/[xy]/g, c => {
                let r = (dt + Math.random() * 16) % 16 | 0
                dt = Math.floor(dt / 16)
                return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
              })
          uuid = uuid + '.' + doc.name.split('.').pop()
          let fileRename = new File([doc], uuid, {
            type: doc.type,
            lastModified: doc.lastModified,
          })
          scope.documentsUpload.push(fileRename)
          scope.AlldocumentsUpload.push(fileRename)
          scope.documentsUploadName.push(uuid)
        }
      })
      // this.documentsUpload = null;
    },

    // Delete selected evidence to registry of record
    deleteFile(doc, index) {
      if (this.editedIndexSurvey > 0) {
        this.AlldocumentsUpload.splice(index, 1)
      }
      this.documentsUpload.splice(index, 1)
      this.documentsUploadName.splice(index, 1)
      this.updating = true
    },

    dialogTemplateClose(option) {
      if (option) {
        this.dialogAddComent = false
      } else {
        this.filesSelected = []
        this.documentsUploadName = []
        this.documentsUpload = []
        this.dialogUpload = false
      }
    },

    dialogTemplateSave(option) {
      if (option) {
        if (this.coment_text !== null) {
          this.inspection.questionSectionList
              .find(f => f.id === this.sectionSelected)
              .questions.find(q => q.id === this.questionSelected.id).coments =
              this.coment_text
          this.coment_text = null
        }
        this.dialogAddComent = false
      } else {
        let question = this.inspection.questionSectionList
            .find(f => f.id === this.sectionSelected)
            .questions.find(q => q.id === this.questionSelected.id)
        question.documents = this.documentsUploadName
        question.updating = this.updating ? this.updating : question.updating
        this.filesSelected = []
        this.documentsUploadName = []
        this.documentsUpload = []
        this.dialogUpload = false
      }
    },

    checkAccess(section) {
      return !!_.find(section.responsables, {id: this.profile.id})
    },

    getAnswerTreatment(question) {
      let answers =
          question.answers_names && question.answers_names.length > 0
              ? []
              : null
      //ONLY FOR STRUCTURES TYPE NUMBER
      if (
          ['NUMBER'].includes(question.questionStructureType) &&
          question.answers_names &&
          question.answers_names.length > 0 &&
          question.answers_names[0] === ''
      ) {
        question.answers_names = []
      }
      if (
          STRUCTURE_TYPE.SIMPLE.includes(question.questionStructureType) &&
          question.answers_names &&
          question.answers_names.length > 0
      ) {
        let data =
            question.answers_names[0] instanceof Date
                ? moment(question.answers_names[0]).format().slice(0, 19)
                : question.answers_names[0]

        let answerSelected = null
        if (!['TIME', 'DATA', 'DATATIME',].includes(question.questionStructureType)) {
          answerSelected = _.find(question.possibleAnswer, {
            answer_name: data,
          })
        }

        answers.push({
          answerPunctuation: answerSelected
              ? answerSelected.punctuation
              : answers && answers.length > 0
                  ? question.questionPunctuation
                  : 0,
          answer: LanguajeService.setKey3(
              data,
              question.answers && question.answers.length > 0
                  ? question.answers[0].answer
                  : data,
              this.editedIndexSurvey > -1 &&
              !question.answers &&
              typeof question.answers[0].answer === 'object'
                  ? null
                  : this.languageStructure
          ),
        })
      } else {
        _.forEach(question.answers_names, (name, index) => {
          const answerSelected = _.find(question.possibleAnswer, {
            answer_name: name,
          })
          const comparison =
              question.answers &&
              question.answers.length > 0 &&
              question.answers[index] &&
              question.answers[index].length > 0 &&
              question.answers[index].answer &&
              question.answers[index].answer.length > 0
          answers.push({
            answerPunctuation: answerSelected
                ? answerSelected.punctuation
                : 0,
            answer: LanguajeService.setKey3(
                name,
                comparison ? question.answers[index].answer : name,
                this.editedIndexSurvey > -1 &&
                !question.answers &&
                question.answers[index].answer === 'object' &&
                comparison
                    ? null
                    : this.languageStructure
            ),
          })
        })
      }
      return answers
    },

    validateInspectionOrSection(type, object) {
      let count = 0
      let questions = []

      if (type === 'inspection') {
        _.forEach(object.questionSectionList, (section) => {
          if (this.checkAccess(section)) {
            _.forEach(section.questions, (question) => {
              if (question.isRequired) {
                if (!(question.answers_names.length > 0)) {
                  questions.push(question)
                  count++
                }
              }
            })
          }
        })

        count += _.filter(this.isValidDate, valid => {
          return !valid
        }).length > 0

        return count > 0;
      } else if (type === 'section') {
        _.forEach(object.questions, (question) => {
          if (question.isRequired) {
            if (!(question.answers_names.length > 0)) {
              questions.push(question)
              count++
            }
          }
        })

        return count > 0 || !this.valid2[object.id];
      }
    },

    // SURVEY
    async saveSurveyAction() {
      const validation = this.validateInspectionOrSection('inspection', this.inspection)

      if (validation) {
        console.log('error')
        return
      }

      this.loadingSave = true
      let inspection = Object.assign({}, this.inspection)

      if(inspection && Array.isArray(inspection.locations)) {
        inspection.locations = inspection.locations.map(location => ({ id: location.id }));
        inspection.primaryLocation = typeof this.location_origin != "object" ? this.location_origin : this.location_origin.id;
      }

      const currentDateTime = new Date().toISOString()
      if (inspection.data)
        inspection.data = moment(inspection.data).format().slice(0, 19)

      inspection.questionSectionList = _.map(
          this.inspection.questionSectionList,
          section => {
            return {
              ...section,
              isFinished: true,
              answeredSection: currentDateTime,
              questions: _.map(section.questions, (question, index) => {
                const answers = this.getAnswerTreatment(question)
                return {
                  ...question,
                  answers: answers,
                  questionHelp: !question.questionHelp
                      ? {}
                      : this.setQuestionHelp(question.questionHelp),
                  possibleAnswer: !question.possibleAnswer
                      ? this.languageStructure
                      : this.setPosibleAnswers(question.possibleAnswer),
                  responsable:
                      answers &&
                      answers.length > 0 &&
                      question.show &&
                      question.show === false
                          ? question.responsable
                          ? question.responsable
                          : null
                          : this.profile,
                }
              }),
            }
          }
      )

      let signature = null
      if (inspection.signatureRequired) {
        if (this.signature.file) {
          inspection.signatures = [
            {
              signature: this.signature.name,
              description: this.signature.description,
            },
          ]
          signature = this.signature.file
          inspection.updateSignatures =
              this.editedIndexSurvey > -1 && this.signature.file ? true : false
        }
      }

      const document = {
        documentAuthority: {id: 1},
        documentType: {id: 1},
        documentDescription: {id: 1},
        company: {id: inspection.company.id},
      }

      this.saveSurvey([
        inspection,
        this.editedIndexSurvey,
        this.AlldocumentsUpload,
        document,
        signature,
        this.$toast,
      ]).finally(() => {
        this.$store.commit('inspections/SET_ORIGINAL_STATE_INSPECTION')
        localStorage.removeItem('inspection')
        this.loadingSave = false
        this.$router.push({name: 'inspections'})
      })
    },

    setQuestionHelp(questionHelp) {
      return questionHelp
    },

    setPosibleAnswers(posibleAnswers) {
      let posibleAnwersMap = []
      _.forEach(posibleAnswers, posibleAnswer => {
        posibleAnwersMap.push(posibleAnswer)
      })
      return posibleAnwersMap
    },

    publish() {
      const changeStateDTO = {
        surveyStateType: 'PUBLISHED',
        surveyId: this.inspection.id,
      }
      this.change_state([changeStateDTO, this.$toast]).finally(() => {
        this.back()
      })
    },

    back() {
      this.$store.commit('signature/SIGNATURE_ORIGINAL_STATE')
      this.$store.commit('inspections/SET_ORIGINAL_STATE_INSPECTION')
      this.$router.push({name: 'inspections'})
    },

    getStorageFiltered() {
      return this.location_origin
          ? _.filter(this.getStorageByAction(), storage => {
            if (storage.primaryLocation.id === this.location_origin) {
              return storage
            }
          })
          : []
    },

    getLocationsByAction() {
      return _.filter(this.locationsList, {active: true})
    },

    getStorageByAction() {
      return _.filter(this.allStoragesByCompany, {active: true})
    },

    surveyPunctuation() {
      let punctuation = 0
      _.forEach(this.inspection.questionSectionList, section => {
        punctuation += section.punctuation
      })
      return punctuation
    },

    sectionPunctuation(sectionId) {
      return parseInt(
          _.sumBy(
              _.find(this.inspection.questionSectionList, {id: sectionId})
                  .questions,
              'answerPunctuation'
          ),
          10
      )
    },

    getMapObjct(objects, id) {
      return _.find(objects, {id: id})
    },

    check(permisions) {
      return PermisionService.check(permisions)
    },

    dialogShow(params) {
      this.title = params.title
      this.dialogText = params.dialogText
      this.showDialog = true
    },

    dialogClose() {
      this.showDialog = false
    },
    replace(event) {
      event.currentTarget.value = event.currentTarget.value
          .toString()
          .replaceAll('"', '')
    },
    replaceComentText(event) {
      this.coment_text = event.currentTarget.value
          .toString()
          .replaceAll('"', '')
    },
  },

  destroyed() {
    this.$store.commit('signature/SIGNATURE_ORIGINAL_STATE')
    this.$root.$off("checkExtra");
  }
}
</script>

<style scoped>
.table-th-style {
  border: hidden !important;
  font-size: 18px;
  color: rgba(19, 19, 19, 0.63) !important;
  font-weight: 300;
  font-family: 'Roboto', sans-serif !important;
}

.v-stepper {
  box-shadow: none !important;
}

.v-expansion-panel::before {
  box-shadow: none;
}

.theme--light.v-expansion-panels .v-expansion-panel:not(:first-child)::after {
  border: none !important;
}

.tooltip__style {
  width: 250px !important;
  max-width: 250px !important;
  background-color: #101010 !important;
}
</style>
